import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"
import API from "../../api"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container, Modal, ModalBody, ModalHeader,
  Spinner,
  Table
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"

const AddNoteDS = props => {
  if (localStorage.getItem("userAuthScolarite")) {
    var userAuthScolarite = JSON.parse(
      localStorage.getItem("userAuthScolarite")
    )
    var user_id = userAuthScolarite.user.id
    var annee_id = userAuthScolarite.user.annee_id
  }
  const [etudiantList, setEtudiantList] = useState([])
  const [loading, setLoading] = useState(false)
  const group = props.location.state.group
  const matiere = props.location.state.matiere
  const group_id = props.location.state.group_id
  const matiere_id = props.location.state.matiere_id
  const type_examen_id = props.location.state.type_examen_id
  const date = props.location.state.date
  const semestre_id = props.location.state.semestre_id
  const enseignant_id = props.location.state.enseignant_id
  const [modal, setModal] = useState(false)
  const [modalErreur, setModalErreur] = useState(false)
  const [modalAfficher, setModalAfficher] = useState(false)
  const [modalAnnuler, setModalAnnuler] = useState(false)
  const [etat, setEtat] = useState(0)
  const [etatAfficher, setEtatAfficher] = useState(0)
  useEffect(async () => {
    const resE = await API.post("examen/passer/etudiants", {
      group_id: group_id,
      type_examen_id: type_examen_id,
      matiere_id: matiere_id,
      date: date,
      semestre_id: semestre_id,annee_id
    }).then(resE => {
      setEtudiantList(resE.data.Note.etudiants)
      setEtat(resE.data.Note.etat)
      setEtatAfficher(resE.data.Note.etat_afficher)
      setLoading(true)
    })
  }, [])
  const toggle = async row => {
    setModal(!modal)
  }
  const toggleAfficher = async row => {
    setModalAfficher(!modalAfficher)
  } 
  const toggleAfficherErreur = async row => {
    setModalErreur(!modalErreur)
  }
  const changeNote = (event, index) => {
    if (/^[0-9,.]+$/.test(event)) {
      var note = event
    } else {
      toast.error("⛔ Note doit comporte seulement des chiffres !", {
        containerId: "A",
      })
    }
    if (parseFloat(note) >= 0 && parseFloat(note) <= 20) {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { note: note }) : el
        )
      )
    } else if (note == "") {
      setEtudiantList(
        etudiantList.map((el, id) =>
          id === index ? Object.assign(el, { note: "" }) : el
        )
      )
    } else {
      toast.error("⛔ Veuillez verifier la note saisie !!", {
        containerId: "A",
      })
    }
  }

  const save = async () => {
    setLoading(false)
    const res = await API.post("scolarite/examen/passer/note/edit", {
      user_id: user_id,
      group_id: group_id,
      matiere_id: matiere_id,
      type_examen_id: type_examen_id,
      semestre_id: semestre_id,
      date: date,
      etudiants: etudiantList,annee_id
    }).then(res => {
      props.history.push("/NoteDS")
    })
  }
  const toggleValider = async () => {
  const res = await API.post("examen/passer/note/valider/scolarite", {
      group_id: group_id,
      matiere_id: matiere_id,
      type_examen_id: type_examen_id,
      enseignant_id: enseignant_id,
      semestre_id: semestre_id,
      date: date,
      valide:1,
      etudiants: etudiantList,
      annee_id,
      user_id
    }).then(res => {
      setModal(false)
      const resE =  API.post("examen/passer/etudiants", {
        group_id: group_id,
        type_examen_id: type_examen_id,
        matiere_id: matiere_id,
        date: date,
        semestre_id: semestre_id,
        annee_id
      }).then(resE => {
        setEtudiantList(resE.data.Note.etudiants)
        setEtat(resE.data.Note.etat)
        setEtatAfficher(resE.data.Note.etat_afficher)
        setLoading(true)
      })
    })
  }
  const toggleAffichage = async () => {
    const res = await API.post("examen/passer/note/affichier", {
      group_id: group_id,
      matiere_id: matiere_id,
      type_examen_id: type_examen_id,
      enseignant_id: enseignant_id,
      semestre_id: semestre_id,
      date: date,
      affiche:1,
      etudiants: etudiantList,
      annee_id,
      user_id
    }).then(res => {
      setModalAfficher(false)
      const resE =  API.post("examen/passer/etudiants", {
        group_id: group_id,
        type_examen_id: type_examen_id,
        matiere_id: matiere_id,
        date: date,
        semestre_id: semestre_id,annee_id
      }).then(resE => {
        setEtudiantList(resE.data.Note.etudiants)
        setEtat(resE.data.Note.etat)
        setEtatAfficher(resE.data.Note.etat_afficher)
        setLoading(true)
      })
    })
  }
  const toggleAnnuler = async row => {
    setModalAnnuler(!modalAnnuler)
  }
  const Devalider = async () => {
    // setModalDelete(!modalDelete)
    setLoading(false)
    const res = await API.post("examen/passer/note/desvalider_note", {
      group_id: group_id,
      matiere_id: matiere_id,
      type_examen_id: type_examen_id,
      enseignant_id: enseignant_id,
      semestre_id: semestre_id,
      date: date,
      valide:0,
      etudiants: etudiantList,
      annee_id,
      user_id
    }).then(res => {
      setModalAnnuler(false)
      const resE =  API.post("examen/passer/etudiants", {
        group_id: group_id,
        type_examen_id: type_examen_id,
        matiere_id: matiere_id,
        date: date,
        semestre_id: semestre_id,
        annee_id
      }).then(resE => {
        setEtudiantList(resE.data.Note.etudiants)
        setEtat(resE.data.Note.etat)
        setEtatAfficher(resE.data.Note.etat_afficher)
        setLoading(true)
      })
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        {loading ? (
          <Container fluid>
            <Card>
              <CardBody>
                <div className="mt-2">
                  <h5 style={{ color: "red" }}>
                    {" "}
                    NB : Cette action est irréversible
                  </h5>
                  <p>
                    {
                      "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
                    }
                  </p>
                </div>
                <div className="d-flex">
                  <CardTitle className="h4 mt-4">Groupe :</CardTitle>
                  <CardTitle
                    style={{ paddingLeft: "5px", color: "#556ee6" }}
                    className="h4 mt-4"
                  >
                    {group}
                  </CardTitle>
                </div>
                <div className="d-flex">
                  <CardTitle className="h4 mb-4">Matière :</CardTitle>
                  <CardTitle
                    style={{ paddingLeft: "5px", color: "#556ee6" }}
                    className="h4 mb-4"
                  >
                    {matiere}
                  </CardTitle>
                </div>
                <div>
                  <Table className="mt-3" striped bordered hover>
                    <thead>
                      <tr>
                        <th style={{ textAlign: "center" }}>CIN</th>
                        <th style={{ textAlign: "center" }}>Etudiant</th>
                        <th style={{ textAlign: "center" }}>Présence</th>
                        <th style={{ textAlign: "center" }}>Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {etudiantList.map((el, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center" }}> {el.cin} </td>
                          <td style={{ textAlign: "center" }}> {el.nom} </td>
                          <td
                            className="square-switch"
                            style={{ textAlign: "center" }}
                          >
                            <input
                              type="checkbox"
                              id={"square-switch" + index}
                              switch="bool"
                              checked={el.isPresent}
                              disabled
                            />
                            <label
                              htmlFor={"square-switch" + index}
                              data-on-label="P"
                              data-off-label="A"
                            />
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <input
                              type="text"
                              onChange={e => changeNote(e.target.value, index)}
                              value={el.note != null ? el.note : ""}
                              style={{ textAlign: "center" }}
                            ></input>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className="mt-2">
                    <h5 style={{ color: "red" }}>
                      {" "}
                      NB : Cette action est irréversible
                    </h5>
                    <p>
                      {
                        "Faites attention ! en cas d'erreur vous n'avez pas la permission de mettre à jour. vous serez obligé de contacter le service administratif"
                      }
                    </p>
                  </div>
                  <div style={{display: "flex",justifyContent: "center", }}>
                    <Col lg="3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="warning"
                          className="btn btn-warning  mb-2 me-2"
                          onClick={() => props.history.push("/NoteDS")}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={save}
                        >
                          Confirmer
                        </button>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                        className="text-center mt-4"
                      >
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={toggle}
                        >
                          {"Valider la note après vérification de l'enseignant"}
                        </button>
                      </div>
                    </Col>
                    {etat === 0 ? (
                      <Col lg="3">
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                          className="text-center mt-4"
                        >
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={toggleAfficherErreur}
                          >
                            {"Afficher la note dans l'espace étudiant"}
                          </button>
                        </div>
                      </Col>
                    ) : etatAfficher === 1 ?  (
                      <Col lg="3">
                        <div style={{ display: "flex", justifyContent: "center" }} className="text-center mt-4">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={toggleAfficher}
                          >
                            {"Afficher la note dans l'espace étudiant"}
                          </button>
                        </div>
                      </Col>
                    ) : (
                      <Col lg="3">
                        <div style={{display: "flex",justifyContent: "center"}} className="text-center mt-4">
                          <button type="button" className="btn btn-success">
                            {"Afficher la note dans l'espace étudiant"}
                          </button>
                        </div>
                        <p style={{ display: "flex",justifyContent: "center", color: "red"}}>
                          Cette note est déjà afficher
                        </p>
                      </Col>
                    )}
                  </div>
                </div>
                <Col lg="12">
                  <div style={{display: "flex",justifyContent: "center"}} className="text-center mt-4">
                    <button type="button" className="btn btn-pink" onClick={toggleAnnuler}>
                      {"Annulation la validation de l'espace d'enseignant"}
                    </button>
                  </div>
                </Col>
              </CardBody>
            </Card>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              Chargement en cours...
            </h4>
          </div>
        )}
        <Modal isOpen={modal} toggle={toggle} centered={true}>
          <ModalHeader
            style={{ width: "100% !important", textAlign: "center !important" }}
            toggle={toggle}
            tag="h4"
          >
            {"Validation de note après la vérification de l'enseignant"}
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>{"Voulez-vous valider la note d'enseignant ?"}</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <Button
                    color="info"
                    className="btn-rounded "
                    onClick={toggleValider}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={toggle}
                    color="danger"
                    className="btn-rounded "
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={modalErreur} toggle={toggleAfficherErreur}centered={true}>
          <ModalHeader
            style={{
              width: "100% !important",
              textAlign: "center !important",
              color: "red",
            }}
            toggle={toggleAfficherErreur}
            tag="h4"
          >
            {"Erreur "}
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>
                  {
                    "Il vous faut valider les notes après leur affichage dans l'espace étudiant."
                  }
                </p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <Button
                    onClick={toggleAfficherErreur}
                    color="danger"
                    className="btn-rounded "
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Fermer
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={modalAfficher} toggle={toggleAfficher} centered={true}>
          <ModalHeader
            style={{ width: "100% !important", textAlign: "center !important" }}
            toggle={toggleAfficher}
            tag="h4"
          >
            {"Affichage de note dans l'espace étudiant"}
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>{"Voulez-vous afficher la note dans l'espace étudiant ?"}</p>
              </div>
              <div
                className="hvr-push"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <Button
                    color="info"
                    className="btn-rounded "
                    onClick={toggleAffichage}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={toggleAfficher}
                    color="danger"
                    className="btn-rounded "
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={modalAnnuler} toggle={toggleAnnuler} centered={true}>
          <ModalHeader
            style={{ width: "100% !important", textAlign: "center !important" }}
            toggle={toggleAnnuler}
            tag="h4"
          >
            {"Annulation de validation d'enseignant"}
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                fontFamily: "Montserrat, sans-serif",
                FontSize: "14px",
                FontWeight: "700",
                LineHeight: "18.375px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>{ "Êtes-vous sûr de vouloir annuler cette action ?"}</p>
              </div>
              <div
                className="hvr-push mt-4"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "15px",
                }}
              >
                <div>
                  <Button
                    color="info"
                    className="btn-rounded "
                    onClick={Devalider}
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-up"
                    ></i>
                    Oui
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={toggleAnnuler}
                    color="danger"
                    className="btn-rounded "
                  >
                    <i
                      style={{ color: "white" }}
                      className="far fa-thumbs-down"
                    ></i>
                    Non
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
    </React.Fragment>
  )
}

export default withRouter(AddNoteDS)
AddNoteDS.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
}
