import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {
  Row,
  Col,
  Spinner,
  Container,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody, CardTitle,
} from "reactstrap"
import { withRouter } from "react-router"
import { ToastContainer, toast, Slide } from "react-toastify"
import FileDownload from "js-file-download"
import $ from "jquery"
import warning from "../../assets/images/téléchargement.png"

const ListEtudiantNoteDS2 = props => {
  if (localStorage.getItem("userAuthScolarite")) {
    var userAuthScolarite = JSON.parse(
        localStorage.getItem("userAuthScolarite")
    )
    var user_id = userAuthScolarite.user.id
    var annee_id = userAuthScolarite.user.annee_id
  }

  const state_element = props.location.state.state_element
  const element = props.location.state.element
  const niveau_id = props.location.state.niveau_id
  const session_id = props.location.state.session_id
  const semestre_id = props.location.state.semestre_id
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [modal, setModal] = useState(false)
  const [matiere, setMatiere] = useState("")
  const [niveau, setNiveau] = useState("")

  const [modalConfirm, setModalConfirm] = useState(false)
  const [modalErreur, setModalErreur] = useState(false)
  const [modalAfficher, setModalAfficher] = useState(false)
  const [modalValidateNote, setModalValidateNote] = useState(false)
  const [etat, setEtat] = useState(0)
  const [etatAfficher, setEtatAfficher] = useState(0)
  const [modalAnnuler, setModalAnnuler] = useState(false)
  const NoDataIndication = () => (
      <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "ordre",
      text: "Ordre",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "cin",
      text: "CIN",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "name",
      text: "Nom & Prénom",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Note",
      isDummyField: true,
      text: "Note",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
          <input
              className="input_test"
              type="text"
              id={row.etudiant_id}
              onChange={e => {
                let inputValue = e.target.value.replace(/[^0-9.,]/g, '');
                inputValue = inputValue.replace(',', '.');
                const parts = inputValue.split(/[.,]/);
                if (parts.length > 1) {
                  const decimals = parts[1].slice(0, 2);
                  inputValue = `${parts[0]}.${decimals}`;
                }
                e.target.value = inputValue;
                changeNote(e, row);
              }}
              defaultValue={row.note}
              style={{ textAlign: "center" }}
          />
          // <input
          //     type="number"
          //     id={row.etudiant_id}
          //     onChange={e => changeNote(e, row)}
          //     defaultValue={row.note}
          //     style={{ textAlign: "center" }}
          // ></input>
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const resE = await API.post("ds2/list_etudiant_note", {
      ds2_id: state_element.id,annee_id
    }).then(res => {
      setList(res.data.ListEtudiant.data_arr)
      setMatiere(res.data.ListEtudiant.matiere)
      setNiveau(res.data.ListEtudiant.niveau)
      setEtat(res.data.ListEtudiant.etat)
      setEtatAfficher(res.data.ListEtudiant.etat_afficher)
      setLoading(true)
    })
  }, [])

  const changeNote = (event, element) => {
    if (/^[0-9,.]+$/.test(event.target.value)) {
      var note = event.target.value
    } else {
      toast.error("⛔ Note doit comporte seulement des chiffres !", {
        containerId: "A",
      })
    }
    var note = event.target.value
    if (parseFloat(note) >= 0 && parseFloat(note) <= 20) {
      setList(
          list.map((el, id) =>
              el.etudiant_id === element.etudiant_id
                  ? Object.assign(el, { note: note })
                  : el
          )
      )
    } else if (note == "") {
      setList(
          list.map((el, id) =>
              el.etudiant_id === element.etudiant_id
                  ? Object.assign(el, { note: "" })
                  : el
          )
      )
    } else {
      setModal(true)
      var note1 = note.slice(0, -1)
      $("#" + element.etudiant_id).val(note1)
    }
  }

  const close = () => {
    setModal(false)
  }

  const toggleConfirm = () => {
    setModalConfirm(!modalConfirm)
  }

  const save = async () => {
    const res = await API.post("ds2/add_note_scolarite", {
      user_id: user_id,
      list: list,
      ds2_id: state_element.id,
    })
        .then(res => {
          setModalConfirm(false)
          props.history.push({
            pathname: "/ListMatieresNoteDS2",
            state: {
              element: element,
              niveau_id: niveau_id,
              semestre_id: semestre_id,
            },
          })
        })
        .catch(() => {
          toast.error("⛔  Problème lors de l'insertion !", {
            containerId: "A",
          })
        })
  }

  const importList = async () => {
    const res = await API.post(
        "ds2/export",
        {
          ds2_id: state_element.id,
        },
        { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, "NotesExamenDS2.xls")
    })
  }
  const toggle = async row => {
    setModalValidateNote(!modalValidateNote)
  }
  const toggleAfficher = async row => {
    setModalAfficher(!modalAfficher)
  }
  const toggleAnnuler = async row => {
    setModalAnnuler(!modalAnnuler)
  }
  const toggleAfficherErreur = async row => {
    setModalErreur(!modalErreur)
  }
  const toggleValider = async () => {
    const res = await API.post("ds2/valider/scolarite", {
      ds2_id: state_element.id,
      valide:1,
      annee_id,
      user_id
    }).then(res => {
      setModalValidateNote(false)
      const resE =  API.post("ds2/list_etudiant_note", {
        ds2_id: state_element.id,
        annee_id
      }).then(resE => {
        setList(resE.data.ListEtudiant.data_arr)
        setMatiere(resE.data.ListEtudiant.matiere)
        setNiveau(resE.data.ListEtudiant.niveau)
        setEtat(resE.data.ListEtudiant.etat)
        setEtatAfficher(resE.data.ListEtudiant.etat_afficher)
        setLoading(true)
      })
    })
  }
  const toggleAffichage = async () => {
    const res = await API.post("ds2/afficher/scolarite", {
      ds2_id: state_element.id,
      afficher:1,
      annee_id,
      user_id
    }).then(res => {
      setModalAfficher(false)
      const resE =  API.post("ds2/list_etudiant_note", {
        ds2_id: state_element.id,
        annee_id
      }).then(resE => {
        setList(resE.data.ListEtudiant.data_arr)
        setMatiere(resE.data.ListEtudiant.matiere)
        setNiveau(resE.data.ListEtudiant.niveau)
        setEtat(resE.data.ListEtudiant.etat)
        setEtatAfficher(resE.data.ListEtudiant.etat_afficher)
        setLoading(true)
      })
    })
  }
  const Devalider = async () => {
    // setModalDelete(!modalDelete)
    setLoading(false)
    const res = await API.post("ds2/desvalider_note", {
      ds2_id: state_element.id,
      user_id,
      annee_id
    }).then(res => {
      setModalAnnuler(false)
      const resE =  API.post("ds2/list_etudiant_note", {
        ds2_id: state_element.id,
        annee_id
      }).then(resE => {
        setList(resE.data.ListEtudiant.data_arr)
        setMatiere(resE.data.ListEtudiant.matiere)
        setNiveau(resE.data.ListEtudiant.niveau)
        setEtat(resE.data.ListEtudiant.etat)
        setEtatAfficher(resE.data.ListEtudiant.etat_afficher)
        setLoading(true)
      })
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">
                Niveau : <span style={{ color: "#556ee6" }}>{niveau}</span>
              </CardTitle>
              <CardTitle className="h4 mb-4">
                Matière : <span style={{ color: "#556ee6" }}>{matiere} </span>
              </CardTitle>
              {loading ? (
                <Fragment>
                  <Row className="mt-4">
                    <Col xs="12">
                      <ToolkitProvider
                        keyField="id"
                        data={list}
                        columns={Columns()}
                        search
                        bootstrap4
                      >
                        {toolkitProps => (
                          <div>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm="12">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn mb-2 me-2"
                                    onClick={importList}
                                  >
                                    <i className="fas fa-file-import me-1" />
                                    Import
                                  </Button>
                                  <div className="text-sm-end">
                                    <a
                                      className="col-md-6"
                                      href={
                                        "https://scolarite.backcresus-institut.ovh/api/list_pdfDS2" +
                                        "/" +
                                        state_element.id +
                                        "/" +
                                        annee_id
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <button className="btn btn-primary btn btn-primary">
                                        <i className="fas fa-file-pdf font-size-16 align-middle me-2"></i>
                                        Import PDF
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <BootstrapTable
                              wrapperClasses="table-responsive"
                              noDataIndication={() => <NoDataIndication />}
                              striped={false}
                              bordered={false}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"table-light"}
                              hover
                              {...toolkitProps.baseProps}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <Col lg="3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="text-center mt-4"
                          >
                            <Button
                              type="button"
                              color="warning"
                              className="btn btn-warning  mb-2 me-2"
                              onClick={() =>
                                props.history.push({
                                  pathname: "/ListMatieresNoteDS2",
                                  state: {
                                    element: element,
                                    niveau_id: niveau_id,
                                    semestre_id: semestre_id,
                                  },
                                })
                              }
                            >
                              Annuler
                            </Button>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="text-center mt-4"
                          >
                            <button
                              type="button"
                              className="btn btn-primary "
                              onClick={toggleConfirm}
                            >
                              CONFIRMER
                            </button>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                            className="text-center mt-4"
                          >
                            <button
                              type="button"
                              className="btn btn-success"
                              onClick={toggle}
                            >
                              {
                                "Valider la note après vérification de l'enseignant"
                              }
                            </button>
                          </div>
                        </Col>
                        {etat === 0 ? (
                          <Col lg="3">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="text-center mt-4"
                            >
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={toggleAfficherErreur}
                              >
                                {"Afficher la note dans l'espace étudiant"}
                              </button>
                            </div>
                          </Col>
                        ) : etatAfficher === 1 ? (
                          <Col lg="3">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                              className="text-center mt-4"
                            >
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={toggleAfficher}
                              >
                                {"Afficher la note dans l'espace étudiant"}
                              </button>
                            </div>
                          </Col>
                        ) : (
                          <Col lg="3">
                            <div style={{display: "flex",justifyContent: "center"}} className="text-center mt-4">
                              <button type="button" className="btn btn-danger">
                                {"Afficher la note dans l'espace étudiant"}
                              </button>
                            </div>
                            <p style={{ display: "flex",justifyContent: "center", color: "red"}}>
                              Cette note est déjà afficher
                            </p>
                          </Col>
                        )}

                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <div style={{display: "flex",justifyContent: "center"}} className="text-center mt-4">
                        <button type="button" className="btn btn-pink" onClick={toggleAnnuler}>
                          {"Annulation la validation de l'espace d'enseignant"}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Fragment>

              ) : (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner type="grow" className="ms-6" color="primary" />
                  </div>
                  <h4
                    style={{ textAlign: "center", marginTop: "2%" }}
                    className="ms-6"
                  >
                    Chargement ...
                  </h4>
                </div>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
      <Modal isOpen={modal} centered={true}>
        <ModalHeader style={{ color: "red" }} toggle={close} tag="h4">
          Faite Attention
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center">
            <img
              src={warning}
              alt=""
              style={{ width: 100, textAlign: "center" }}
            />
          </div>
          <p className="mt-4" style={{ textAlign: "center" }}>
            <strong> Veuillez verifier la note saisie </strong>
          </p>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalConfirm} toggle={toggleConfirm} centered={true}>
        <ModalHeader
          style={{ width: "100% !important", textAlign: "center !important" }}
          toggle={toggleConfirm}
          tag="h4"
        >
          Confirmation
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Vos étes sur de changer des notes ?</p>
            </div>
            <div
              className="hvr-push mt-4"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button color="info" className="btn-rounded " onClick={save}>
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleConfirm}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modalValidateNote} toggle={toggle} centered={true}>
        <ModalHeader
          style={{ width: "100% !important", textAlign: "center !important" }}
          toggle={toggle}
          tag="h4"
        >
          {"Validation de note après la vérification de l'enseignant"}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>{"Voulez-vous valider la note d'enseignant ?"}</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleValider}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggle}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalErreur} toggle={toggleAfficherErreur} centered={true}>
        <ModalHeader
          style={{
            width: "100% !important",
            textAlign: "center !important",
            color: "red",
          }}
          toggle={toggleAfficherErreur}
          tag="h4"
        >
          {"Erreur "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>
                {
                  "Il vous faut valider les notes après leur affichage dans l'espace étudiant."
                }
              </p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleAfficherErreur}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Fermer
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalAfficher} toggle={toggleAfficher} centered={true}>
        <ModalHeader
          style={{ width: "100% !important", textAlign: "center !important" }}
          toggle={toggleAfficher}
          tag="h4"
        >
          {"Affichage de note dans l'espace étudiant"}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>{"Voulez-vous afficher la note dans l'espace étudiant ?"}</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={toggleAffichage}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleAfficher}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalAnnuler} toggle={toggleAnnuler} centered={true}>
        <ModalHeader
          style={{ width: "100% !important", textAlign: "center !important" }}
          toggle={toggleAnnuler}
          tag="h4"
        >
          {"Annulation de validation d'enseignant"}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>{ "Êtes-vous sûr de vouloir annuler cette action ?"}</p>
            </div>
            <div
              className="hvr-push mt-4"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  color="info"
                  className="btn-rounded "
                  onClick={Devalider}
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleAnnuler}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(ListEtudiantNoteDS2)
ListEtudiantNoteDS2.propTypes = {
  list: PropTypes.array,
  history: PropTypes.object,
  location: PropTypes.object,
  t: PropTypes.any,
}
