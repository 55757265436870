import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"
import API from "../../api"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap"

const ListMatieres = props => {
  const state_element = props.location.state.element
  const niveau_id = props.location.state.niveau_id
  const session_id = props.location.state.session_id
  const semestre_id = props.location.state.semestre_id
  const [listExamen, setListExamen] = useState([])
  const [loading, setLoading] = useState(false)
  var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
  var annee_id = anneeScolaire.Annee.id
  useEffect(async () => {
    setLoading(false)
    const resE = await API.post("examen/final/list_matiere", {
      niveau_id: niveau_id,
      cycle_id: state_element.cycle_id,
      specialite_id: state_element.specialite_id,
      session_id: session_id,
      semestre_id: semestre_id,annee_id
    }).then(resE => {
      setListExamen(resE.data.ListMatieres)
      setLoading(true)
    })
  }, [props.niveau])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {loading ? (
            <Row className="mt-4">
              {listExamen.length != 0 ? (
                listExamen.map((element, key) => (
                  <Col
                    onClick={() =>
                      props.history.push({
                        pathname: "/ListSalle",
                        state: {
                          state_element: element,
                          element: state_element,
                          niveau_id: niveau_id,
                        },
                      })
                    }
                    xl="3"
                    sm="4"
                    key={"_col_" + key}
                  >
                    <Card
                      outline
                      color="primary"
                      className="border border-primary"
                    >
                      <CardBody style={{ cursor: "pointer" }}>
                        <div className="mt-2 text-center cursor-pointer">
                          <h5>{element.date}</h5>
                        </div>
                        <div className="mt-2 text-center cursor-pointer">
                          <h5>{element.quart_examen}</h5>
                        </div>
                        <div className="mt-4 text-center">
                          <h5 style={{ color: "#556ee6" }}>
                            {element.matiere}
                          </h5>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))
              ) : (
                <Card>
                  <CardBody>
                    <h4 className="text-center mt-4">
                      Aucun examen pour le moment 😉
                    </h4>
                  </CardBody>
                </Card>
              )}
            </Row>
          ) : (
            <div className="mt-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                <Spinner type="grow" className="ms-6" color="primary" />
              </div>
              <h4
                style={{ textAlign: "center", marginTop: "2%" }}
                className="ms-6"
              >
                {" "}
                {"Chargement"} ...{" "}
              </h4>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(ListMatieres))
ListMatieres.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  niveau: PropTypes.object,
  t: PropTypes.any,
}
