import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import APIS from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner, CardTitle, Label, Form } from "reactstrap"
import { withRouter } from "react-router"
//i18n
import { withTranslation } from "react-i18next"
import API from "../../api"
import FileDownload from "js-file-download"
import Select from "react-select"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"

const DataTableNote = props => {

  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [typeExamen, setTypeExamen] = useState([])
  const [selectTypeExamen, setSelectTypeExamen] = useState("")
  const [groupCour, setGroupCour] = useState([])
  const [selectGroupCour, setSelectGroupCour] = useState([])
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  if (localStorage.getItem("anneeScolaire")) {
    var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
    var annee = anneeScolaire.Annee.annee
    var annee_id = anneeScolaire.Annee.id
  }
  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: props.t("Suivant"),
    prePageText: props.t("Précedent"),
    alwaysShowAllBtns: true,
    sizePerPage: 20,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>Aucun information à afficher</h6>
  )
  const Columns = () => [
    {
      dataField: "date",
      text: props.t("Date"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "type_examen",
      text: props.t("Type examen"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "matiere",
      text: props.t("Matiere"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "group",
      text: props.t("Groupe"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "semestre",
      text: props.t("Semestre"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Note",
      isDummyField: true,
      text: props.t("Note"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.active == 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "cornflowerblue", cursor: "pointer" }}
              className="fas fa-edit"
              onClick={() =>
                props.history.push({
                  pathname: "/AddNoteDS",
                  state: {
                    enseignant_id:row.enseignant_id,
                    group_id: row.groupe_id,
                    matiere: row.matiere,
                    group: row.group,
                    matiere_id: row.matiere_id,
                    type_examen_id: row.type_examen_id,
                    date: row.date,
                    semestre_id: row.semestre_id,

                  },
                })
              }
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#A7A3A3", cursor: "pointer" }}
              className="fas fa-edit"
            ></i>
          </div>
        ),
    },
    {
      dataField: "Export",
      isDummyField: true,
      text: props.t("Export"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        row.active == 1 ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#319f57", cursor: "pointer", fontSize: "15px" }}
              className="fas fa-file-excel"
              onClick={() => upload(row)}
            ></i>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i
              style={{ color: "#A7A3A3", cursor: "pointer", fontSize: "15px" }}
              className="fas fa-file-excel"
            ></i>
          </div>
        ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await APIS.post("scolarite/examen/passer/list",{annee_id}).then(res => {
      setOrders(res.data.Eamen)
      setLoading(true)
    })
    const resType = await APIS.get("type_examen/select").then(resT => {
      setTypeExamen(resT.data.Type_examen)
    })
  }, [])

  const upload = async row => {
    if (localStorage.getItem("anneeScolaire")) {
      var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
      var annee = anneeScolaire.Annee.annee
      var annee_id = anneeScolaire.Annee.id
    }
    const res = await API.post(
      "scolarite/examen/passer/export",
      {
        group_id: row.groupe_id,
        matiere_id: row.matiere_id,
        date:row.date,
        type_examen_id: row.type_examen_id,
        semestre_id: row.semestre_id,
        annee_name:annee,
        annee_id
      },
      { responseType: "blob" }
    ).then(res => {
      FileDownload(res.data, "NotesDS(" + row.group + ").xls")
    })
  }

  const search = async () => {
    setOrders([])
    var group_id = selectGroupe !== "" && selectGroupe!= null  ? selectGroupe.value : 0
    var matiere_id = selectMatiere !== "" && selectMatiere!= null ? selectMatiere.value : 0
    var typeExamen_id = selectTypeExamen !== "" && selectTypeExamen!= null ? selectTypeExamen.value : 0
    var semestre_id = selectSemestre !== "" && selectSemestre!= null ? selectSemestre.value : 0
    var group_cour_id = selectGroupCour !== "" && selectGroupCour!= null ? selectGroupCour.value : 0
    const res = await API.post("scolarite/examen/passer/search", {
      group_id: group_id,
      matiere_id: matiere_id,
      typeExamen_id: typeExamen_id,
      semestre_id: semestre_id,
      group_cour_id: group_cour_id,
      annee_id
    }).then(res => {
      setOrders(res.data.Eamen)
    })
  }

  const searchAll = async () => {
    const res = await APIS.post("scolarite/examen/passer/list",{annee_id}).then(res => {
      setOrders(res.data.Eamen)
    })
  }
  const uploadPDFGroup = async () => {
    var matiere_id = selectMatiere !== "" && selectMatiere != null ? selectMatiere.value : 0
    var typeExamen_id = selectTypeExamen !== "" && selectTypeExamen != null ? selectTypeExamen.value : 0
    var semestre_id = selectSemestre !== "" && selectSemestre != null ? selectSemestre.value : 0
    var group_cour_id = selectGroupCour !== "" && selectGroupCour != null ? selectGroupCour.value : 0
    if(group_cour_id === 0 || semestre_id === 0 || matiere_id === 0){
      setLoading(true)
      toast.error("⛔  Groupe , matière et semestre sont obligatoire pour le exportation !", {containerId: "A"})
    }else{
      window.open('https://scolarite.backcresus-institut.ovh/api/list_pdf_group/'+group_cour_id+'/'+ matiere_id+ '/' + typeExamen_id +'/'+semestre_id+'/'+annee_id,'_blank')
    }
  }
  const uploadGroup = async () => {
    setOrders([])
    var matiere_id = selectMatiere != "" ? selectMatiere.value : 0
    var typeExamen_id = selectTypeExamen != "" ? selectTypeExamen.value : 0
    var semestre_id = selectSemestre != "" ? selectSemestre.value : 0
    var group_cour_id = selectGroupCour != "" ? selectGroupCour.value : 0
    if(group_cour_id == 0 || semestre_id == 0 || matiere_id == 0){
      setLoading(true)
      toast.error("⛔  Groupe , matière et semestre sont obligatoire pour le exportation !", {
        containerId: "A",
      })
    }else{
      if (localStorage.getItem("anneeScolaire")) {
        var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"))
        var annee = anneeScolaire.Annee.annee
      }
      const res = await API.post(
          "scolarite/examen/passer/exports",
          {
            group_cour_id: group_cour_id,
            matiere_id: matiere_id,
            type_examen_id: typeExamen_id,
            semestre_id: semestre_id,
            annee:annee,
            annee_id
          },
          { responseType: "blob" }
      ).then(res => {
        FileDownload(res.data, "NotesDSGroupe(" + selectGroupCour.label + ").xls")
      })
    }
  }
/////// get matiere ////
  const getMatiere = async event => {
    setSelectMatiere("")
    setSelectGroupe(event)
    var group_id = event.value
    const res = await API.post("matiere/get_matiere_by_groupe", { group_td_id: group_id }).then(
        res => {
          setMatiere(res.data.Matiere)
        }
    )
  }
  const getGroup = async event => {
    setSelectGroupCour("")
    setSelectGroupe("")
    setSelectMatiere("")
    setSelectSemestre(event)
    var semestre_id = event.value
    const res = await API.post("group/get_group_cour", { semestre_id: semestre_id }).then(
        res => {
          setGroupCour(res.data.Group)
        }
    )
  }
  const getGroupTD = async event => {
    setSelectGroupCour(event)
    setSelectGroupe("")
    setSelectMatiere("")
    const resGroup = await APIS.post("group/td/by_group_cour_id",{group_cour_id:event.value}).then(res => {
      setGroupe(res.data.Group)
    })
    const resMatiere = await APIS.post("matiere/get_matiere_by_groupe_cour",{group_cour_id:event.value}).then(res => {
      setMatiere(res.data.Matiere)
    })

  }
  /////
  return (
    <React.Fragment>
      <div>
        <CardTitle style={{ color: "#556ee6" }} className="h4">
          Critéres de Recherches
        </CardTitle>
        <Form className="mt-4">
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Semestre :</Label>
                <Select
                    options={semestre}
                    isSearchable={true}
                    // isClearable={true}
                    value={selectSemestre }
                    onChange={e => getGroup(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Groupe :</Label>
                <Select
                  options={groupCour}
                  // isClearable={true}
                  isSearchable={true}
                  onChange={e => getGroupTD(e)}
                  value={selectGroupCour}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Groupe TD :</Label>
                <Select
                    options={groupe}
                    // isClearable={true}
                    isSearchable={true}
                    onChange={e => getMatiere(e)}
                    value={selectGroupe || 0}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-lastname-input2">Matière :</Label>
                <Select
                    options={matiere}
                    // isClearable={true}
                    isSearchable={true}
                    value={selectMatiere}
                    onChange={e => setSelectMatiere(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>

            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Type examen : </Label>
                <Select
                  options={typeExamen}
                  isSearchable={true}
                  // isClearable={true}
                  onChange={e => setSelectTypeExamen(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div
          className="mb-6"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          {" "}
          <Col lg="3">
            <div className="text-center mt-4">
              <button
                type="button"
                style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                className="btn btn-success mb-2 me-2"
                onClick={search}
              >
                Rechercher
              </button>
            </div>
          </Col>
          <Col lg="3">
            <div className="text-center mt-4">
              <button
                  type="button"
                  style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                  className="btn btn-success mb-2 me-2"
                  onClick={uploadGroup}
              ><i
                  style={{ color: "#red", cursor: "pointer", fontSize: "15px", margin:"0px 6px 0px 0px" }}
                  className="fas fa-file-excel"
              ></i>
                Exportation par groupes
              </button>
            </div>
          </Col>
          <Col lg="3">
            <div className="text-center mt-4">
              <a  className="btn btn-success mb-2 me-2" onClick={uploadPDFGroup}
                  style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                  target="_blank"
                  rel="noreferrer"
              ><i
                  style={{ color: "#red", cursor: "pointer", fontSize: "15px" , margin:"0px 6px 0px 0px" }}
                  className="fas fa-file-pdf"
              ></i>
                Exportation par groupes PDF
              </a>
            </div>
          </Col>
          <Col lg="3">
            <div className="text-center mt-4">
              <button
                type="button"
                style={{ backgroundColor: "#761C19", borderColor: "#761C19" }}
                className="btn btn-success mb-2 me-2"
                onClick={searchAll}
              >
                Afficher Tout
              </button>
            </div>
          </Col>
        </div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
      <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
      />
      {/* <> */}
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableNote))
DataTableNote.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.any,
  t: PropTypes.any,
}
