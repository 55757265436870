import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {Col, Input, Row, Label, Form, Button, Table, ModalHeader, ModalBody, Modal} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"
import Select from "react-select";
import warning from "../../assets/images/téléchargement.png";
const AddNoteLibre = props => {
    if (localStorage.getItem("userAuthScolarite")) {
        var userAuthScolarite = JSON.parse(
            localStorage.getItem("userAuthScolarite")
        )
        var anneeScolaire = JSON.parse(
            localStorage.getItem("anneeScolaire")
        )
        var user_id = userAuthScolarite.user.id
        var ann = anneeScolaire.Annee.annee
        var annee_id = anneeScolaire.Annee.id
    }
    const [disbutReg, setDisbutReg] = useState(true)
    const [cycle, setCycle] = useState([])
    const [selectCycle, setSelectCycle] = useState("")
    const [selectNiveau, setSelectNiveau] = useState("")
    const [niveau, setNiveau] = useState([])
    const [semestre, setSemestre] = useState([
        { value: 1, label: "Semestre 1" },
        { value: 2, label: "Semestre 2" },
    ])
    const [selectSemestre, setSelectSemestre] = useState("")
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [specialite, setSpecialite] = useState([])
    const [matiere, setMatiere] = useState([])
    const [selectMatiere, setSelectMatiere] = useState("")
    const [session, setSession] = useState([])
    const [selectSession, setSelectSession] = useState("")
    const [groupe, setGroupe] = useState([]);
    const [selectGroupe, setSelectGroupe] = useState("");
    const [affiche, setAffiche] = useState(false)
    const [modal, setModal] = useState(false)
    const [modalConfirm, setModalConfirm] = useState(false)
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [etudiantList, setEtudiantList] = useState([])
    useEffect(async () => {
        // cycle
        const res = await API.get("cycle/select").then(res => {
            setCycle(res.data.Cycle)
        })

        //ssssion
        const resS = await API.get("session/select").then(resS => {
            setSession(resS.data.Session)
        })
    }, [])

    const getSpecialite = async event => {
        setSelectSemestre(event)
        setSelectSpecialite("")
        setSelectMatiere("")
        setSelectSession("")
        const res = await API.post("specialite/get_by_niveaux_cycle_semestre", {
            semestre_id: event,
            niveau_id: selectNiveau.value,
            cycle_id: selectCycle.value,
        }).then(res => {
            setSpecialite(res.data.Specialites)
        })
    }
    const getMatiere = async event => {
        setSelectSpecialite(event)
        setSelectMatiere("")
        setSelectSession("")
        const res = await API.post(
            "matiere/get_by_cycle_niveaux_semestre_specialites",
            {
                specialite_id: event.value,
                semestre_id: selectSemestre,
                niveau_id: selectNiveau.value,
                cycle_id: selectCycle.value,
            }
        ).then(res => {
            setMatiere(res.data.Matiere)
        })
        await API.post(
            "group/get_group_by_cyc_niv_sem_spec",
            {
                specialite_id: event.value,
                semestre_id: selectSemestre.value,
                niveau_id: selectNiveau.value,
                cycle_id: selectCycle.value,
                annee_id
            }
        ).then(resGroup => {
            setGroupe(resGroup.data.Group)
        })
    }
    const getCycle = async event =>{
        setSelectCycle(event)
        setSelectNiveau("")
        setSelectSemestre("")
        setSelectSpecialite("")
        setSelectMatiere("")
        setSelectSession("")
        if(event.value == 1){
            //niveau
            const resNiveau = await API.get("niveau/select").then(resNiveau => {
                setNiveau(resNiveau.data.Niveau)
            })
        }else{
            setNiveau([
                { value: 1, label: "1 ère Année" },
                { value: 2, label: "2 ème Année" },
            ])
        }
    }
    const getNiveau = async event =>{
        setSelectNiveau(event)
        setSelectSemestre("")
        setSelectSpecialite("")
        setSelectMatiere("")
        setSelectSession("")

    }
    const display = () => {
        if (selectSemestre == "" || selectGroupe == "") {
            setAffiche(false)
            toast.error("⛔ Tous les champs sont obligatoire !", {
                containerId: "A",
            })
        } else {
            API.post("group/get_etd_by_group_semestre", {
                semestre_id: selectSemestre.value,
                group_id: selectGroupe.value,
                annee_id
            }).then(res => {
                setEtudiantList(res.data.Etudiant)
                setAffiche(true)

            })
        }
    }
    const save = async () => {
        const res = await API.post("examen/final/add_note_scolarite_libre", {
            user_id: user_id,
            etudiantList: etudiantList,
            cycle_id:selectCycle.value,
            niveau_id:selectNiveau.value,
            semestre_id:selectSemestre.value,
            specialite_id:selectSpecialite.value,
            matiere_id:selectMatiere.value,
            group_id:selectGroupe.value,
            session_id:selectSession.value,
            ann:ann,
            annee_id
        })
        .then(res => {
            props.setSection(1)
        })
        .catch(() => {
            toast.error("⛔  Problème lors de l'insertion !", {
                containerId: "A",
            })
        })


    }
    const getGroup = async event =>{
        setSelectGroupe(event)
        setAffiche(false)
    }
    const close = () => {
        setModal(false)
    }

    const toggleConfirm = () => {
        setModalConfirm(!modalConfirm)
    }
    const changeNote = (event, index) => {
        if (/^[0-9,.]+$/.test(event)) {
            var note = event
        } else if (/,./.test(event)) {
            var note = event
        } else {
            toast.error("⛔ Note doit comporte seulement des chiffres !", {
                containerId: "A",
            })
        }
        if (parseFloat(note) >= 0 && parseFloat(note) <= 20) {
            setEtudiantList(
                etudiantList.map((el, id) =>
                    id === index ? Object.assign(el, { note: note }) : el
                )
            )
        } else if (note == "") {
            setEtudiantList(
                etudiantList.map((el, id) =>
                    id === index ? Object.assign(el, { note: "" }) : el
                )
            )
        } else {
            toast.error("⛔ Veuillez verifier la note saisie !!", {
                containerId: "A",
            })
        }
    }
    return (
        <React.Fragment>
            <Row>
                <Form>
                    <Row>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Cycle</Label>
                                <Select
                                    options={cycle}
                                    isSearchable={true}
                                    onChange={e => getCycle(e)}
                                    value={selectCycle}
                                />
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Niveau</Label>
                                <Select
                                    options={niveau}
                                    isSearchable={true}
                                    onChange={e => getNiveau(e)}
                                    value={selectNiveau}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Semestre:</Label>
                                <Select
                                    options={semestre}
                                    isSearchable={true}
                                    onChange={e => getSpecialite(e)}
                                    value={selectSemestre}
                                />
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Spécialité</Label>
                                <Select
                                    options={specialite}
                                    isSearchable={true}
                                    onChange={e => getMatiere(e)}
                                    value={selectSpecialite}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Matière")}
                                </Label>
                                <Select
                                    options={matiere}
                                    isSearchable={true}
                                    onChange={e => setSelectMatiere(e)}
                                    placeholder={props.t("Select")}
                                    value={selectMatiere}
                                />
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Session")}
                                </Label>
                                <Select
                                    options={session}
                                    isSearchable={true}
                                    onChange={e => setSelectSession(e)}
                                    value={selectSession}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Groupe")}
                                </Label>
                                <Select
                                    options={groupe}
                                    isSearchable={true}
                                    onChange={e => getGroup(e)}
                                    value={selectGroupe}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {" "}

                        <Col lg="6">
                            <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={display}>
                                    Afficher
                                </button>
                            </div>
                        </Col>
                    </div>
                    {affiche ? (
                        <div>
                            <Table className="mt-3" striped bordered hover>
                                <thead>
                                <tr>
                                    <th style={{ textAlign: "center" }}>Order</th>
                                    <th style={{ textAlign: "center" }}>CIN</th>
                                    <th style={{ textAlign: "center" }}>Etudiant</th>
                                    <th style={{ textAlign: "center" }}>Note</th>
                                </tr>
                                </thead>
                                <tbody>
                                {etudiantList.map((el, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: "center" }}> {el.order} </td>
                                        <td style={{ textAlign: "center" }}> {el.cin} </td>
                                        <td style={{ textAlign: "center" }}> {el.etudiant_name} </td>

                                        <td style={{ textAlign: "center" }}>
                                            <input
                                                type="text"
                                                // onChange={e => changeNote(e.target.value, index)}
                                                value={el.note != null ? el.note : ""}
                                                style={{ textAlign: "center" }}
                                                onChange={e => {
                                                    let inputValue = e.target.value.replace(/[^0-9.,]/g, '');
                                                    inputValue = inputValue.replace(',', '.');
                                                    const parts = inputValue.split(/[.,]/);
                                                    if (parts.length > 1) {
                                                        const decimals = parts[1].slice(0, 2);
                                                        inputValue = `${parts[0]}.${decimals}`;
                                                    }
                                                    e.target.value = inputValue;
                                                    changeNote(e.target.value, index);
                                                }}
                                            ></input>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                {" "}
                                <Col lg="6">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                        }}
                                        className="text-center mt-4"
                                    >
                                        <Button
                                            type="button"
                                            color="warning"
                                            className="btn btn-warning  mb-2 me-2"
                                            onClick={props.back}
                                        >
                                            Annuler
                                        </Button>
                                    </div>
                                </Col>
                                <Col lg="6">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                        }}
                                        className="text-center mt-4"
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-primary "
                                            disabled={!disbutReg}
                                            onClick={save}
                                        >
                                            CONFIRMER
                                        </button>
                                    </div>
                                </Col>
                            </div>
                        </div>

                    ):(null)

                    }
                </Form>

            </Row>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
            <Modal isOpen={modal} centered={true}>
                <ModalHeader style={{ color: "red" }} toggle={close} tag="h4">
                    Faite Attention
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex justify-content-center">
                        <img
                            src={warning}
                            alt=""
                            style={{ width: 100, textAlign: "center" }}
                        />
                    </div>
                    <p className="mt-4" style={{ textAlign: "center" }}>
                        <strong> Veuillez verifier la note saisie </strong>
                    </p>
                </ModalBody>
            </Modal>
            <Modal isOpen={modalConfirm} toggle={toggleConfirm} centered={true}>
                <ModalHeader
                    style={{ width: "100% !important", textAlign: "center !important" }}
                    toggle={toggleConfirm}
                    tag="h4"
                >
                    Confirmation
                </ModalHeader>
                <ModalBody>
                    <div
                        style={{
                            fontFamily: "Montserrat, sans-serif",
                            FontSize: "14px",
                            FontWeight: "700",
                            LineHeight: "18.375px",
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <p>Vos étes sur de changer des notes ?</p>
                        </div>
                        <div
                            className="hvr-push mt-4"
                            style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginBottom: "15px",
                            }}
                        >
                            <div>
                                <Button color="info" className="btn-rounded " onClick={save}>
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-up"
                                    ></i>
                                    Oui
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={toggleConfirm}
                                    color="danger"
                                    className="btn-rounded "
                                >
                                    <i
                                        style={{ color: "white" }}
                                        className="far fa-thumbs-down"
                                    ></i>
                                    Non
                                </Button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}

export default withTranslation()(AddNoteLibre)
AddNoteLibre.propTypes = {
    list: PropTypes.array,
    history: PropTypes.object,
    back: PropTypes.object,
    setSection: PropTypes.object,
    t: PropTypes.any,
}
